import { commafy } from 'commafy-anything';
import { Coerce } from 'declic-app/common';

import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { MAT_INPUT_VALUE_ACCESSOR } from '@angular/material/input';

@Directive({
  selector: '[declicCommafy]',
  providers: [
    { provide: MAT_INPUT_VALUE_ACCESSOR, useExisting: CommafyDirective },
  ],
  standalone: false,
})
export class CommafyDirective {
  readonly NUMBER_REGEX_PATTERN = /[^\d.-]/g;
  private ctrlValue: string;

  constructor(private elementRef: ElementRef<HTMLInputElement>) {}

  get value(): string {
    return this.ctrlValue;
  }

  @Input() set value(val: string) {
    this.ctrlValue = val;
    this.formatNativeElementValue(val);
  }

  @HostListener('input', ['$event.target.value'])
  onInput(value: string): void {
    this.ctrlValue = this.removeNonNumericChars(value);
  }

  @HostListener('blur')
  onBlur(): void {
    this.formatNativeElementValue(this.ctrlValue);
  }

  @HostListener('focus')
  onFocus(): void {
    this.unformatControlValueAndResetNativeElement();
  }

  private formatNativeElementValue(val: string): void {
    const resolvedValue = val ? commafy(Number(val)).replace(',', ' ') : '';
    this.elementRef.nativeElement.value = resolvedValue;
  }

  private unformatControlValueAndResetNativeElement(): void {
    const nativeValue = this.elementRef.nativeElement.value;
    this.ctrlValue = this.removeNonNumericChars(nativeValue);

    this.elementRef.nativeElement.value = this.ctrlValue;
  }

  private removeNonNumericChars(dirtyString: string): string {
    return Coerce.toString(dirtyString, '').replace(
      this.NUMBER_REGEX_PATTERN,
      '',
    );
  }
}
