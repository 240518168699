/* eslint-disable max-lines */
import {
  BaseControl,
  Coerce,
  DeclicUtils,
  reduceToDict,
  validateAnnualForecast,
} from 'declic-app/common';
import { YearlyEmission } from 'declic-app/models';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { filter, map, startWith, take } from 'rxjs/operators';

import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormArray,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { FormControl } from '@ngneat/reactive-forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

type YearlyEmissionsWithIsCascading = {
  emission: YearlyEmission[];
  isCascading: boolean;
};

@UntilDestroy()
@Component({
  selector: 'declic-annual-forecast',
  templateUrl: './annual-forecast.component.html',
  styleUrls: ['./annual-forecast.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AnnualForecastComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AnnualForecastComponent),
      multi: true,
    },
  ],
  standalone: false,
})
export class AnnualForecastComponent extends BaseControl<YearlyEmission[]> {
  private valueOnFocus = 0;
  private openedSubject = new Subject();
  private yearsSubject = new BehaviorSubject<number[]>([]);
  private defaultSubject = new BehaviorSubject<YearlyEmission[]>([]);
  private isReadOnly = new BehaviorSubject<boolean>(false);
  private isCascadingSubject = new BehaviorSubject<boolean>(true);
  public hasDefaultValues = new BehaviorSubject<boolean>(false);
  public isCurrentDisplay = new BehaviorSubject<boolean>(false);

  @Input() set years(years: number[]) {
    this.yearsSubject.next(years || []);
  }

  @Input() set isDisplayCurrent(isCurrent: boolean) {
    this.isCurrentDisplay.next(isCurrent);
  }
  @Input() set default(values: YearlyEmission[]) {
    this.defaultSubject.next(values);
  }
  @Input() unit: string;
  @Input() set label(labelName: string) {
    this.labelName = labelName;
  }
  @Input() set validators(validators: ValidatorFn[]) {
    if (validators) {
      this.setValidatorsOfForecastControlIfProvided([
        ...validators,
        Validators.min(0),
        Validators.required,
      ]);
    }
  }

  @Input() set opened(opened: boolean) {
    this.openedSubject.next(opened);
  }
  @Output() userInput = new EventEmitter();
  @Input() set readOnly(isDisable: boolean) {
    this.isReadOnly.next(isDisable);
  }

  @Input() set isCascading(value: boolean) {
    this.isCascadingSubject.next(value);
  }
  @Input() helpMessageKey: string;

  forecastControl: UntypedFormArray = new UntypedFormArray([]);
  shouldCascadeControl: FormControl<boolean> = new FormControl(true);
  points$: Observable<number[]>;
  isReadOnly$: Observable<boolean> = this.isReadOnly.asObservable();
  labelName: string;

  getControl(): AbstractControl {
    return this.forecastControl;
  }

  getControlValueStream(): Observable<YearlyEmission[]> {
    const control = this.getControl();
    return combineLatest([
      control.valueChanges,
      this.shouldCascadeControl.valueChanges,
    ]).pipe(map(([values]) => values));
  }

  resetControl(): void {
    this.forecastControl.reset([]);
  }

  auxiliaryOnInit(): void {
    this.subscribeForFormBuilding();
    this.subscribeForDefaultPrefilling();
    this.subscribeToOpenedForCasadingSubscription();
    this.points$ = this.getPointsStream();
    this.shouldCascadeControl.patchValue(this.getIsCascadingValue());
  }
  formToParentModel(formVal: YearlyEmission[]): YearlyEmissionsWithIsCascading {
    return {
      emission: [...formVal],
      isCascading: this.shouldCascadeControl.value,
    };
  }

  onReset(): void {
    this.defaultSubject.next(this.defaultSubject.value);
  }

  writeValue(values: YearlyEmissionsWithIsCascading): void {
    setTimeout(() => {
      this.getControl().patchValue(
        DeclicUtils.resolveToEmptyArray(values.emission),
      );
    }, 1);
  }

  patchPastedList(initial: AbstractControl, values: string[]) {
    const forecastControls = this.forecastControl.controls;
    const controlIndex =
      initial !== null ? forecastControls.findIndex((x) => x === initial) : 0;

    this.shouldCascadeControl.patchValue(false);

    values.forEach((value, valueIndex) => {
      const floatValue = parseFloat(value);
      if (
        !isNaN(floatValue) &&
        controlIndex + valueIndex < forecastControls.length
      ) {
        forecastControls[controlIndex + valueIndex].patchValue({
          emissions: floatValue,
        });
      }
    });
  }

  patchPastedListFull(values: string[]) {
    this.patchPastedList(null, values);
  }

  onFieldFocus(value: number): void {
    this.valueOnFocus = value;
  }

  onFieldBlur(value: number): void {
    if (this.isDifferentFromFocusedValue(value)) {
      this.userInput.emit();
    }
  }

  getValue(value: number) {
    return this.isReadOnly.value && value
      ? DeclicUtils.fixToNPlaces(value, 7)
      : value;
  }

  // private subscribeToReadonlyForCascadeControlDisabling(): void {
  //   this.isReadOnly
  //     .pipe(untilDestroyed(this))
  //     .subscribe((is) => this.shouldCascadeControl.setDisable(is));
  // }

  private isDifferentFromFocusedValue(value: number): boolean {
    return value !== this.valueOnFocus;
  }

  private subscribeToOpenedForCasadingSubscription(): void {
    this.openedSubject
      .pipe(filter(Boolean), untilDestroyed(this))
      .subscribe(() => this.subscribeForCascadedPrefilling());
  }

  private setValidatorsOfForecastControlIfProvided(
    validators: ValidatorFn[],
  ): void {
    if (!!validators.length) {
      this.forecastControl.controls.forEach((control: UntypedFormGroup) =>
        control.get('emissions').setValidators(validators),
      );
    }
  }

  private getIsCascadingValue(): boolean {
    return this.isCascadingSubject.value !== undefined
      ? this.isCascadingSubject.value
      : true;
  }

  private getPointsStream(): Observable<number[]> {
    return this.forecastControl.valueChanges.pipe(
      startWith(this.forecastControl.value),
      map((values: YearlyEmission[]) =>
        values.map((emission) => emission.emissions),
      ),
    );
  }

  private subscribeForDefaultPrefilling(): void {
    this.defaultSubject
      .pipe(
        untilDestroyed(this),
        filter((values) => !!Coerce.toArr(values).length),
      )
      .subscribe((values) => {
        this.hasDefaultValues.next(true);
        const emissionManifest = reduceToDict(values, 'year');
        this.forecastControl.controls.forEach((control) => {
          control.patchValue({
            emissions: emissionManifest[control.value.year].emissions,
          });
        });
      });
  }

  private subscribeForFormBuilding(): void {
    this.yearsSubject.pipe(take(1)).subscribe((years) => {
      this.forecastControl.clear();
      years.forEach((year) =>
        this.forecastControl.push(this.createYearlyEmissionForm(year)),
      );
    });
  }

  private createYearlyEmissionForm(year: number): UntypedFormGroup {
    return new UntypedFormGroup({
      // eslint-disable-next-line max-lines
      emissions: new FormControl(null, [
        validateAnnualForecast(this.labelName),
      ]),
      year: new FormControl(year),
    });
  }

  private subscribeForCascadedPrefilling(): void {
    const forecastControls = this.forecastControl.controls;

    // eslint-disable-next-line sonarjs/no-ignored-return
    forecastControls.reduce((idx, yearlyControl) => {
      if (idx < forecastControls.length) {
        this.subscribeControlToPreviousControlValue(
          yearlyControl,
          forecastControls[idx],
        );
      }
      return idx + 1;
    }, 1);
  }

  private subscribeControlToPreviousControlValue(
    previous: AbstractControl,
    next: AbstractControl,
  ): void {
    previous.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value: YearlyEmission) => {
        if (this.shouldCascadeControl.value) {
          next.patchValue({ emissions: value.emissions });
        }
      });
  }
}
