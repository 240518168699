import { Injectable } from '@angular/core';

export type FormatValueArgs = Pick<
  Intl.NumberFormatOptions,
  'maximumFractionDigits' | 'minimumFractionDigits' | 'unit' | 'style'
>;

@Injectable({
  providedIn: 'root',
})
export class ValueFormatterService {
  format(value: number, args: FormatValueArgs = this.defaultArgs): string {
    args = { ...this.defaultArgs, ...args };
    const formatter = new Intl.NumberFormat('fr-FR', args);
    return this.applyCustomization(formatter.format(value));
  }

  private get defaultArgs(): FormatValueArgs {
    return { maximumFractionDigits: 5, style: 'decimal' };
  }

  private applyCustomization(value: string): string {
    return this.replaceFrenchDecimalSeparator(this.replaceDefaultSpace(value));
  }

  private replaceDefaultSpace(value: string): string {
    return value.replace(/\s/gi, ' ');
  }

  private replaceFrenchDecimalSeparator(value: string): string {
    return value.replace(',', '.');
  }
}
