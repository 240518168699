/* istanbul ignore file */
import {
  ErrorHandler,
  Provider,
  inject,
  makeEnvironmentProviders,
  provideAppInitializer,
} from '@angular/core';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';

import { environment } from './environments';

const TAG_API_URL = 'api.url';

const initialize = () =>
  Sentry.init({
    dsn: environment.sentryDsn,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0,
    environment: environment.name,
    denyUrls: ['localhost'],
  });

const provideErrHandler = (): Provider => ({
  provide: ErrorHandler,
  useValue: Sentry.createErrorHandler(),
});

const provideTracing = () =>
  makeEnvironmentProviders([
    { provide: Sentry.TraceService, deps: [Router] },
    provideAppInitializer(() => {
      const traceService = inject(Sentry.TraceService);
    }),
  ]);

const intentionallyThrowError = () => {
  throw new Error('Intentionally throwing an error');
};

const setTag = (key: string, value: string) => Sentry.setTag(key, value);
const captureException = (err: Error) => Sentry.captureException(err);

export const DeclicSentry = {
  initialize,
  provideErrHandler,
  provideTracing,
  intentionallyThrowError,
  TAG_API_URL,
  setTag,
  captureException,
};
