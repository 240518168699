import {
  ABSORPTION_CHILLER_TYPES,
  ELECTRIC_CHILLER_TYPES,
} from '@declic/types';

export function hasElectricChiller(chillerType: string): boolean {
  return ELECTRIC_CHILLER_TYPES.includes(chillerType);
}
export function hasAbsorptionChiller(chillerType: string): boolean {
  return ABSORPTION_CHILLER_TYPES.includes(chillerType);
}
