import { Component, Input, OnInit } from '@angular/core';
import { YearlyEmission } from 'declic-app/models';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'declic-yearly-values',
  templateUrl: './yearly-values.component.html',
  styleUrls: ['./yearly-values.component.scss'],
  standalone: false,
})
export class YearlyValuesComponent implements OnInit {
  private valuesSubject = new BehaviorSubject<YearlyEmission[]>([]);
  @Input() set values(values: YearlyEmission[]) {
    this.valuesSubject.next(values);
  }
  @Input() unit: string;

  values$: Observable<YearlyEmission[]>;

  constructor() {}

  ngOnInit(): void {
    this.values$ = this.valuesSubject.asObservable();
  }
}
