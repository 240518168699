export type CustomUnit = {
  parts: CustomText[];
};

export type CustomText = {
  text: string;
  verticality: Verticality;
};

export enum Verticality {
  NORMAL = 'normal',
  SUPER = 'super',
  SUB = 'sub',
}
