import { ProjectRepository } from 'declic-project/state';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';

import { Component, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

import { version } from '../environments/';
import { OmnibarMasterPage } from './components/omnibar-layout';
import { AgreementService } from './services/agreement';
import { ErrorAnnouncer } from './services/error-announcer';
import { FeatureFlagger } from './services/feature-flag';
import { WebsocketService } from './services/websocket';

@UntilDestroy()
@Component({
  selector: 'declic-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [AgreementService],
  standalone: false,
})
export class AppComponent implements OnInit {
  static TIME_FOR_CHECKING_ACCESS_TOKEN_VALIDITY = 5000;
  private readonly displayLogoutBanner = new BehaviorSubject<boolean>(false);

  readonly displayLogoutBanner$ = this.displayLogoutBanner.asObservable();
  readonly version = version;
  readonly activePages: OmnibarMasterPage[] = [
    { label: 'projects', url: '/projects' },
    { label: 'legacy', url: '/legacy' },
    { label: 'assumptions', url: `/assumptions` },
    { label: 'documentation', url: '/documentation' },
  ];

  constructor(
    private project: ProjectRepository,
    private websocketService: WebsocketService,
    readonly announcer: ErrorAnnouncer,
    private featureFlag: FeatureFlagger,
  ) {}

  ngOnInit(): void {
    this.project.getAll();
    this.initWebsocketConnection();
    this.subscribeToFeatureFlags();
  }

  private initWebsocketConnection() {
    this.websocketService.initWebsocketConnection();
  }

  private subscribeToFeatureFlags() {
    this.featureFlag.getFlags().pipe(take(1)).subscribe();
  }
}
