/* eslint-disable max-lines */
import { Observable } from 'rxjs';

import {
  DHCEquipment,
  Equipment,
  Equipments,
  HasName,
  HistoricalEquipment,
  LCMEquipment,
  LEGEquipment,
  OUEquipment,
  PVSystem,
} from '@declic/types';
import { createStore, propsFactory } from '@ngneat/elf';
import {
  entitiesPropsFactory,
  EntitiesRef,
  getAllEntities,
  getEntity,
  selectActiveEntity,
  selectAllEntities,
  selectEntity,
  setEntities,
  upsertEntities,
  withActiveId,
  withEntities,
} from '@ngneat/elf-entities';

import {
  withError,
  withLoading,
  withLoadingOne,
  withRecentlyProcessed,
} from './factories';

export const { hydroRunUpRiverEntitiesRef, withHydroRunUpRiverEntities } =
  entitiesPropsFactory(Equipments.HYDRO_RUNUP_RIVER);

export const { hydroReservoirEntitiesRef, withHydroReservoirEntities } =
  entitiesPropsFactory(Equipments.HYDRO_RESERVOIR);

export const { offshoreWindFarmEntitiesRef, withOffshoreWindFarmEntities } =
  entitiesPropsFactory(Equipments.OFFSHORE_WIND_FARM);

export const { onshoreWindFarmEntitiesRef, withOnshoreWindFarmEntities } =
  entitiesPropsFactory(Equipments.ONSHORE_WIND_FARM);

export const { pvpEntitiesRef, withPvpEntities } = entitiesPropsFactory(
  Equipments.PV_POWER_PLANT,
);

export const { thermalEnergyEntitiesRef, withThermalEnergyEntities } =
  entitiesPropsFactory(Equipments.THERMAL_ENERGY_EXCHANGER);

export const { standaloneChillerEntitiesRef, withStandaloneChillerEntities } =
  entitiesPropsFactory(Equipments.STANDALONE_CHILLER);

export const { cogenEntitiesRef, withCogenEntities } = entitiesPropsFactory(
  OUEquipment.COGEN,
);

export const { evcEntitiesRef, withEvcEntities } = entitiesPropsFactory(
  LCMEquipment.EVC,
);

export const { hpumpEntitiesRef, withHpumpEntities } = entitiesPropsFactory(
  Equipments.HEAT_PUMP,
);

export const {
  conventionalThermalEntitiesRef,
  withConventionalThermalEntities,
} = entitiesPropsFactory(Equipments.CONVENTIONAL_SOLAR_THERMAL);

export const { solarPvBatteryEntitiesRef, withSolarPvBatteryEntities } =
  entitiesPropsFactory(Equipments.SOLAR_PV_BATTERY);

export const { fuelingStationEntitiesRef, withFuelingStationEntities } =
  entitiesPropsFactory(Equipments.FUELING_STATION);

export const { hpumphcEntitiesRef, withHpumphcEntities } = entitiesPropsFactory(
  Equipments.HEAT_PUMP_HC,
);

export const { trigenerationEntitiesRef, withTrigenerationEntities } =
  entitiesPropsFactory(Equipments.TRIGENERATION);

export const { withBoilerEntities, boilerEntitiesRef } =
  entitiesPropsFactory('boiler');

export const { withPvSystemEntities, pvSystemEntitiesRef } =
  entitiesPropsFactory('pvSystem');

export const { solarPvEntitiesRef, withSolarPvEntities } = entitiesPropsFactory(
  Equipments.SOLAR_PV,
);

export const { withWindFarmEntities, windFarmEntitiesRef } =
  entitiesPropsFactory('windFarm');

export const { withHydroPlantEntities, hydroPlantEntitiesRef } =
  entitiesPropsFactory('hydroPlant');

export const { withPowerGenEntities, powerGenEntitiesRef } =
  entitiesPropsFactory('powerGen');
export const { withActiveType, setActiveType, getActiveType } = propsFactory(
  'activeType',
  {
    initialValue: null as Equipment,
  },
);

export const historicalEquipmentStore = createStore(
  { name: 'historicalEquipments' },
  withEntities<HistoricalEquipment>(),
  withError(),
  withActiveId(),
);

export const equipmentStore = createStore(
  { name: 'equipments' },
  withSolarPvEntities(),
  withPvSystemEntities<PVSystem>(),
  withHydroRunUpRiverEntities(),
  withHydroReservoirEntities(),
  withOnshoreWindFarmEntities(),
  withOffshoreWindFarmEntities(),
  withPvpEntities(),
  withThermalEnergyEntities(),
  withStandaloneChillerEntities(),
  withWindFarmEntities(),
  withHydroPlantEntities(),
  withPowerGenEntities(),
  withBoilerEntities(),
  withCogenEntities(),
  withEvcEntities(),
  withHpumpEntities(),
  withConventionalThermalEntities(),
  withSolarPvBatteryEntities(),
  withFuelingStationEntities(),
  withHpumphcEntities(),
  withTrigenerationEntities(),
  withLoading(),
  withLoadingOne(),
  withActiveType(),
  withActiveId(),
  withRecentlyProcessed(),
);

export const EquipmentRefs: Partial<Record<Equipment, EntitiesRef>> = {
  [LEGEquipment.PV_SYTEM]: pvSystemEntitiesRef,
  [Equipments.SOLAR_PV]: solarPvEntitiesRef,
  [LEGEquipment.WIND_TURBINE]: windFarmEntitiesRef,
  [LEGEquipment.HYDRO_PLANT]: hydroPlantEntitiesRef,
  [LEGEquipment.POWER_GENERATOR]: powerGenEntitiesRef,
  [DHCEquipment.BOILER]: boilerEntitiesRef,
  [OUEquipment.COGEN]: cogenEntitiesRef,
  [LCMEquipment.EVC]: evcEntitiesRef,
  [Equipments.HEAT_PUMP]: hpumpEntitiesRef,
  [Equipments.STANDALONE_CHILLER]: standaloneChillerEntitiesRef,
  [Equipments.THERMAL_ENERGY_EXCHANGER]: thermalEnergyEntitiesRef,
  [Equipments.PV_POWER_PLANT]: pvpEntitiesRef,
  [Equipments.ONSHORE_WIND_FARM]: onshoreWindFarmEntitiesRef,
  [Equipments.OFFSHORE_WIND_FARM]: offshoreWindFarmEntitiesRef,
  [Equipments.HYDRO_RESERVOIR]: hydroReservoirEntitiesRef,
  [Equipments.HYDRO_RUNUP_RIVER]: hydroRunUpRiverEntitiesRef,
  [Equipments.CONVENTIONAL_SOLAR_THERMAL]: conventionalThermalEntitiesRef,
  [Equipments.SOLAR_PV_BATTERY]: solarPvBatteryEntitiesRef,
  [Equipments.FUELING_STATION]: fuelingStationEntitiesRef,
  [Equipments.HEAT_PUMP_HC]: hpumphcEntitiesRef,
  [Equipments.TRIGENERATION]: trigenerationEntitiesRef,
};

function setEnts<T>(entities: T[], type: Equipment): void {
  equipmentStore.update(setEntities(entities, { ref: EquipmentRefs[type] }));
}
function getEnt<T>(id: string, type: Equipment): T {
  return equipmentStore.query(getEntity(id, { ref: EquipmentRefs[type] })) as T;
}
function selectEnts<T>(type: Equipment): Observable<T[]> {
  return equipmentStore.pipe(
    selectAllEntities({ ref: EquipmentRefs[type] }),
  ) as Observable<T[]>;
}
function upsertEnt<T>(entity: T, type: Equipment): void {
  equipmentStore.update(upsertEntities([entity], { ref: EquipmentRefs[type] }));
}
function selectActiveEnt<T>(type: Equipment): Observable<T> {
  return equipmentStore.pipe(
    selectActiveEntity({ ref: EquipmentRefs[type] }),
  ) as Observable<T>;
}

function selectEnt<T>(id: string, type: Equipment): Observable<T> {
  return equipmentStore.pipe(
    selectEntity(id, { ref: EquipmentRefs[type] }),
  ) as Observable<T>;
}

function getNames(type: Equipment): string[] {
  return equipmentStore
    .query(getAllEntities({ ref: EquipmentRefs[type] }))
    .map((e: HasName) => e.name);
}

export const EquipmentStoreMutators = { setEnts, upsertEnt };
export const EquipmentStoreQueries = {
  getEnt,
  selectEnts,
  selectActiveEnt,
  getNames,
  selectEnt,
};
