import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { TranslocoModule } from '@jsverse/transloco';

import { AnnualForecastModule } from '../annual-forecast/annual-forecast.module';
import { YearlyInputModule } from '../yearly-input/yearly-input.module';
import { AnnualFactorComponent } from './annual-factor.component';

@NgModule({
  declarations: [AnnualFactorComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    YearlyInputModule,
    AnnualForecastModule,
    MatMenuModule,
    ReactiveFormsModule,
    TranslocoModule,
  ],
  exports: [AnnualFactorComponent],
})
export class AnnualFactorModule {}
