import { inject, Pipe, PipeTransform } from '@angular/core';
import {
  FormatValueArgs,
  ValueFormatterService,
} from 'declic-app/services/value-formatter';

@Pipe({
  name: 'formatValue',
  standalone: true,
})
export class FormatValuePipe implements PipeTransform {
  private readonly service = inject(ValueFormatterService);

  transform(
    value: number,
    args?: FormatValueArgs,
    defaultValue: string = 'N/A',
  ): unknown {
    return this.nullOrUndefined(value)
      ? defaultValue
      : this.service.format(value, args);
  }

  private nullOrUndefined(value: unknown): boolean {
    return [null, undefined, ''].some((v) => v === value);
  }
}
