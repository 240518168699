/* eslint-disable max-lines*/
import { emissionApproach } from 'declic-app/common';
import { CustomText, Verticality } from 'declic-app/common/label-maker';
import { NETWORKS_ENERGY_SOURCES } from 'declic-app/common/network-energy-source';
import {
  NETWORK_EF_UNITS,
  NETWORK_ENERGY_UNIT,
  UNITS,
} from 'declic-app/common/units';
import {
  AuxiliaryProperty,
  PropertyConstraint,
} from 'declic-app/components/auxiliary-value';
import { ProductAuxiliariesConfig } from 'declic-app/components/product-auxiliaries';
import { AnnualFactor, EnergySource } from 'declic-app/models';
import {
  Fluorinated,
  ScopeOne,
  ScopeOneBE,
  UpstreamCH4,
} from 'declic-app/networks-project-emissions/models';
import { NetworksProject } from 'declic-app/networks-project-emissions/models/network-projects.models';
import { ScopeTwo } from 'declic-app/networks-project-emissions/models/scope2.models';
import { ProjectType } from 'declic-app/project/models';

export type AvoidedEmissionsInput = {
  emissionsApproach: unknown;
  baselineEmissions: unknown;
  projectEmissions: unknown;
  baselineJustification: string;
};

export type ScopeThreeBE = Pick<
  ScopeOneBE,
  'stationaryCombustion' | 'mobileCombustion'
> &
  Pick<ScopeTwo, 'electricityConsumption'> &
  Readonly<
    Partial<{
      transpoOfSoldProds: Fluorinated;
      useOfSoldProds: Fluorinated;
      emissionsOfFeedstock: Fluorinated;
      upstreamOfSold: Fluorinated;
      upstreamFVF: UpstreamCH4;
    }>
  >;

export type ScopeThree = Pick<
  ScopeOne,
  'stationaryCombustion' | 'mobileCombustion'
> &
  Pick<ScopeTwo, 'electricityConsumption'> &
  Pick<
    ScopeThreeBE,
    | 'transpoOfSoldProds'
    | 'useOfSoldProds'
    | 'emissionsOfFeedstock'
    | 'upstreamOfSold'
    | 'upstreamFVF'
  >;

export interface BaselineEmissions {
  emissionFactor: AnnualFactor;
}

export type AvoidedEmissions = {
  emissionsApproach: emissionApproach;
  projectType: ProjectType;
  baselineEmissions: BaselineEmissions;
  projectEmissions: EnergySource[];
};

export interface Biomethane extends NetworksProject {
  scopeOne: ScopeOne;
  scopeTwo: ScopeTwo;
  scopeThree: ScopeThree;
  avoidedEmissions?: AvoidedEmissionsInput;
}

export interface BiomethaneBE extends NetworksProject {
  scopeOne: ScopeOneBE;
  scopeTwo: ScopeTwo;
  scopeThree: ScopeThreeBE;
}

export type Scope1Categories = keyof ScopeOne;

export const BIOMETHANE_COMBUSTION_CONFIG = (
  type: string,
): ProductAuxiliariesConfig => ({
  name: 'fuel',
  props: [
    {
      name: 'elecProduced',
      unit: NETWORK_ENERGY_UNIT[type],
      customUnit: {
        parts: MWhLHVperYear,
      },
      constraints: [],
    },
    {
      name: 'emissionFactor',
      unit: NETWORK_EF_UNITS[type],
      customUnit: {
        parts: tCO2eMWhLHV,
      },
      constraints: [PropertyConstraint.ZERO_TO_1],
      disabled: true,
    },
  ],
  types: NETWORKS_ENERGY_SOURCES.biomethanes.scope1[type],
  averageOnly: false,
  yearlyOnly: true,
});

export const BIOMETHANE_CH4_CONFIG = (
  type: string,
): ProductAuxiliariesConfig => ({
  name: 'ch4Volume',
  props: [
    {
      name: 'volume',
      unit: UNITS.volume,
      constraints: [],
    },
    {
      name: 'gwp',
      unit: UNITS.gwp,
      constraints: [],
      disabled: true,
    },
  ],
  types: NETWORKS_ENERGY_SOURCES.biomethanes.scope1[type],
  averageOnly: false,
  yearlyOnly: true,
});

export const BIOMETHANE_FLUORINATED_CONFIG = (
  type: string,
): ProductAuxiliariesConfig => ({
  name: 'fluorinatedGas',
  props: [
    {
      name: 'volume',
      unit: NETWORK_ENERGY_UNIT[type],
      constraints: [],
    },
    {
      name: 'gwp',
      unit: NETWORK_EF_UNITS[type],
      constraints: [PropertyConstraint.ZERO_TO_1],
      disabled: true,
    },
  ],
  types: NETWORKS_ENERGY_SOURCES.biomethanes.scope1[type],
  averageOnly: false,
  yearlyOnly: true,
});

export const MOBILE_COMBUSTION_CONFIG = (
  type: string,
): ProductAuxiliariesConfig => ({
  ...BIOMETHANE_FLUORINATED_CONFIG(type),
  name: 'fuel',
  props: [
    {
      name: 'volume',
      unit: NETWORK_ENERGY_UNIT[type],
      constraints: [],
    },
    {
      name: 'emissionFactor',
      unit: NETWORK_EF_UNITS[type],
      constraints: [PropertyConstraint.ZERO_TO_1],
      disabled: true,
    },
  ],
});

const ELECTRICITY_PROP_VOLUME: AuxiliaryProperty = {
  name: 'elecVolume',
  unit: NETWORK_EF_UNITS.elecVolume,
  constraints: [],
};

const ELECTRICITY_PROP_GEF: AuxiliaryProperty = {
  name: 'gef',
  unit: NETWORK_EF_UNITS.gef,
  constraints: [],
  disabled: true,
};

export const EMISSION_ELECTRICITY: ProductAuxiliariesConfig = {
  name: 'elecValue',
  props: [ELECTRICITY_PROP_VOLUME, ELECTRICITY_PROP_GEF],
  types: NETWORKS_ENERGY_SOURCES.biomethanes.scope2.electricityConsumption,
  averageOnly: false,
  yearlyOnly: true,
};

export const MWhLHVperYear = [
  { text: 'MWh', verticality: Verticality.NORMAL },
  { text: '[LHV]', verticality: Verticality.SUB },
  { text: '/year', verticality: Verticality.NORMAL },
] as CustomText[];

export const tCO2eMWhLHV = [
  { text: 'tCO2e/MWh', verticality: Verticality.NORMAL },
  { text: '[LHV]', verticality: Verticality.SUB },
] as CustomText[];
