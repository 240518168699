export type HasType = { type: string };
export type HasEF = { ef: number };
export type HasdirectEmissionFactor = { directEmissionFactor: number };
export type HasGWP = { gwp: number };
export type HasCustomEF = {
  directEmissionFactor: Record<number, number>;
  emissionFactor: Record<number, number>;
  isCascadingForDirect: boolean;
  isCascadingForIndirect: boolean;
  isCustom?: boolean;
};
export type HasSharefV5Fields = {
  declic_name: string;
  source: string;
  technology: string;
  subtype: string;
  displayName: string;
};
export type HasColdSource = {
  coldSource: string;
  coldEmission: number;
  coldDisplayName: string;
};
export type HasHeatSource = {
  heatSource: string;
  heatEmission: number;
  heatDisplayName: string;
};

export type EquipmentFuel = HasType &
  HasSharefV5Fields & {
    lifecycleEf: number;
    name?: string;
    isCustom: boolean;
  };

export type ProjectFuel = HasType &
  HasEF & {
    fuelType: string;
  };

export type Refrigerant = HasType & HasGWP;

export type ProjectElec = HasType & {
  ef: Record<number, number>;
};

export type GetFuelsRsp = { fuels: EquipmentFuel[] };
export type GetProjFuelsRsp = { fuels: ProjectFuel[] };
export type GetRefrigerantsRsp = { refrigerants: Refrigerant[] };
export type GetProjElecsRsp = ProjectElec[];
