import {
  Directive,
  EventEmitter,
  HostBinding,
  HostListener,
  Output,
} from '@angular/core';

export interface Drag extends DragEvent, Event {}

@Directive({
  selector: '[declicDrop]',
  standalone: false,
})
export class DropDirective {
  readonly defaultColor = 'transparent';
  readonly highLightColor = '#9ecbec';
  @Output('declicDrop') outputList = new EventEmitter<string[]>();

  @HostBinding('style.background-color') background: string;
  constructor() {}

  @HostListener('drop', ['$event'])
  onDrop(event: Drag) {
    this.toggleBgColor(event);
    const files: FileList = event.dataTransfer.files;
    if (this.isValidCsvFile(files)) {
      const reader = new FileReader();
      reader.onload = this.parseCsvContent(reader);
      reader.readAsText(files[0]);
    }
  }

  private isValidCsvFile(files: FileList): boolean {
    return [this.isFileTypeCsv(files), files.length === 1].every(Boolean);
  }

  @HostListener('dragover', ['$event'])
  onDragOver(event: Drag): void {
    this.toggleBgColor(event, this.highLightColor);
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave(event: Drag): void {
    this.toggleBgColor(event);
  }

  private parseCsvContent(reader: FileReader): () => void {
    return () => {
      const csv = reader.result as string;
      const strDelimiter = !!/\r|\n/.exec(csv) ? /\r?\n/ : ',';
      const values = csv
        .split(strDelimiter)
        .filter((val) => !!val)
        .map((val) => val.replace(/,/g, '.').replace(/"/g, ''));
      this.outputList.emit(values);
    };
  }

  private isFileTypeCsv(File: FileList): boolean {
    return File[0].name.split('.').slice(-1).includes('csv');
  }

  private toggleBgColor(event: Drag, color: string = this.defaultColor): void {
    event.preventDefault();
    event.stopPropagation();
    this.background = color;
  }
}
