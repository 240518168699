import { Coerce } from 'declic-app/common/coercion.utils';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  ActivitySummary,
  CommonHistoFuel,
  COMPLEX_EQUIPMENT_KEYS,
  COMPLEX_EQUIPMENTS,
  EquipmentFuel,
  HistoAbsorptionChiller,
  HistoCogen,
  HistoElectricChiller,
  HistoricalEquipment,
  HistoricalEquipmentForm,
} from '@declic/types';
import { filterNil } from '@ngneat/elf';
import { selectActiveEntity, selectAllEntities } from '@ngneat/elf-entities';

import { activitySummaryStore, selectSummary } from './activities.store';
import { historicalEquipmentStore } from './equipment.store';

function selectActiveInForm(): Observable<HistoricalEquipmentForm> {
  return selectTruthyActive().pipe(map((e) => toForm(e)));
}

function selectTruthyActive(): Observable<HistoricalEquipment> {
  return historicalEquipmentStore.pipe(selectActiveEntity(), filterNil());
}

function toForm(equipment: HistoricalEquipment): HistoricalEquipmentForm {
  return {
    ...equipment,
    ...toFormForComplexEquipmentAttributes(equipment),
    fuel: {
      type: equipment.declic_name,
      lifecycleEf: equipment.emissionsFactor,
      declic_name: equipment.declic_name,
      source: equipment.source,
      technology: equipment.technology,
      subtype: equipment.subtype,
      displayName: equipment.displayName,
    } as EquipmentFuel,
    coldSource: {
      type: equipment.coldSource,
      lifecycleEf: equipment.coldEmission,
      displayName: equipment.coldDisplayName,
    } as EquipmentFuel,
    heatSource: {
      type: equipment.heatSource,
      lifecycleEf: equipment.heatEmission,
      displayName: equipment.heatDisplayName,
    } as EquipmentFuel,
  };
}

function toFormForComplexEquipmentAttributes(equip: HistoricalEquipment) {
  return {
    cogen: toCogenForm(equip),
    electricChiller: toElectricChillerForm(equip),
    absorptionChiller: toAbsorptionChilleForm(equip),
  };
}

function toCogenForm(equip: HistoricalEquipment): HistoCogen<EquipmentFuel> {
  if (!equip.cogen) return null;
  return { ...equip.cogen, fuel: toFormForFuel(equip.cogen) };
}
function toElectricChillerForm(
  equip: HistoricalEquipment,
): HistoElectricChiller {
  if (!equip.electricChiller) return null;
  return {
    ...equip.electricChiller,
    coldSource: {
      type: equip.electricChiller.coldSource,
      displayname: equip.electricChiller.coldDisplayName,
      lifecycleEf: equip.electricChiller.coldEmission,
    } as unknown as string,
    // fuels: [...equip.electricChiller.fuels],
    // fuel: toFormForFuel(equip.electricChiller),
  };
}
function toAbsorptionChilleForm(
  equip: HistoricalEquipment,
): HistoAbsorptionChiller {
  if (!equip.absorptionChiller) return null;
  return {
    ...equip.absorptionChiller,
    coldSource: {
      type: equip.absorptionChiller.coldSource,
      displayname: equip.absorptionChiller.coldDisplayName,
    } as unknown as string, // quick fix, to be updated with proper way lol
    // fuels: [...equip.absorptionChiller.fuels],
    // fuel: toFormForFuel(equip.absorptionChiller),
  };
}

function toFormForFuel(equip: CommonHistoFuel<string>): EquipmentFuel {
  return {
    type: equip.declic_name,
    lifecycleEf: equip.emissionsFactor,
    declic_name: equip.declic_name,
    displayName: equip.displayName,
  } as EquipmentFuel;
}

function toPersistence(form: HistoricalEquipmentForm): HistoricalEquipment {
  const coercedForm = Coerce.toObj(form);
  const parsedFuel = parseFuel(coercedForm.fuel);

  return {
    ...form,
    ...parseFuel(coercedForm.coldSource, 'cold'),
    ...parseFuel(coercedForm.heatSource, 'heat'),
    ...parsedFuel,
    declic_name: parsedFuel.fuel,
    ...parseComplexEquipmentFuels(coercedForm),
  } as HistoricalEquipment;
}

function selectHistoricalMoreThanActivityProd(): Observable<boolean> {
  return combineLatest([
    selectAllProductions(),
    activitySummaryStore.pipe(selectSummary()),
  ]).pipe(map(([prods, summary]) => totalExceedsTotal(prods, summary)));
}

function totalExceedsTotal(prods: number[], summary: ActivitySummary): boolean {
  return sumProds(prods) > summary.totalProduction;
}

function sumProds(prods: number[]): number {
  return prods.reduce((acc, curr) => acc + curr, 0);
}

function selectAllProductions(): Observable<number[]> {
  return historicalEquipmentStore.pipe(
    selectAllEntities(),
    map((ents) => toProductions(ents)),
  );
}

function toProductions(ents: HistoricalEquipment[]): number[] {
  return ents.map(
    (e) =>
      Coerce.toNum(e.productionHeat) +
      Coerce.toNum(e.productionCold) +
      Coerce.toNum(e.productionPower),
  );
}

function parseComplexEquipmentFuels(
  form: HistoricalEquipmentForm,
): Partial<HistoricalEquipment> {
  let parsedComplexEquipAttr = {};
  if (COMPLEX_EQUIPMENTS.includes(form.equipment)) {
    parsedComplexEquipAttr = COMPLEX_EQUIPMENT_KEYS.filter(
      (key) => form[key],
    ).reduce((acc, key) => {
      acc[key] = {
        ...form[key],
        declic_name: parseDeclicName(form[key].fuel),
        ...parseFuel(form[key].coldSource, 'cold'),
        ...parseFuel(form[key].heatSource, 'heat'),
        ...parseFuel(form[key].fuel),
      };
      return acc;
    }, {});
  }
  return parsedComplexEquipAttr;
}

function parseDeclicName(fuel: EquipmentFuel): string {
  return Coerce.toObj(fuel).type;
}

function parseFuel(fuel: EquipmentFuel, prefix: string = '') {
  const { lifecycleEf, type, displayName } = Coerce.toObj(fuel);
  let sourceKey = 'fuel';
  let emissionKey = 'emissionsFactor';
  let displayNameKey = 'displayName';
  if (prefix) {
    sourceKey = prefix + 'Source';
    emissionKey = prefix + 'Emission';
    displayNameKey = prefix + 'DisplayName';
  }
  return {
    [sourceKey]: type,
    [emissionKey]: lifecycleEf,
    [displayNameKey]: displayName,
  };
}

export const HistoEquipQueries = {
  selectActiveInForm,
  toPersistence,
  selectHistoricalMoreThanActivityProd,
};
