import {
  HasCapacity,
  HasEquipmentType,
  HasFuels,
  HasYearlyProduction,
} from './equipment.types';
import { TableEntity } from './generic.types';

export enum FuelingStationType {
  H2 = 'h2',
  GAS = 'gas',
}
export const FUELING_STATION_TYPES = Object.values(FuelingStationType);
export type HasFuelingStationType = { fuelingStationType: FuelingStationType };

export type FuelingStation = TableEntity &
  HasEquipmentType &
  HasCapacity &
  HasYearlyProduction &
  HasFuels &
  HasFuelingStationType;

export type FuelingStationForm = Pick<
  FuelingStation,
  'name' | 'capacity' | 'yearlyProduction' | 'fuels' | 'fuelingStationType'
>;
