import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

export interface Paste extends ClipboardEvent, Event {}

@Directive({
  selector: '[declicPaste]',
  standalone: false,
})
export class PasteDirective {
  @Output('declicPaste') outputList = new EventEmitter<string[]>();
  constructor() {}

  @HostListener('paste', ['$event'])
  onPaste(event: Paste) {
    event.preventDefault();
    if (event?.clipboardData.types[0] === 'text/plain') {
      const pastedString = event?.clipboardData?.getData('text');
      this.outputList.emit(this.prepPastedStringValues(pastedString));
    }
  }

  private prepPastedStringValues(rawStr: string): string[] {
    if (/\t/.test(rawStr)) {
      return this.parseString(rawStr, /\t/);
    }
    const strDelimiter = !!/\r|\n/.exec(rawStr) ? /\r?\n/ : ',';
    return this.parseString(rawStr, strDelimiter);
  }

  private parseString(rawStr: string, dlimiter: RegExp | string): string[] {
    return rawStr.split(dlimiter).map((str) => {
      return str.replace(/,/g, '.').replace(/"/g, '').replace(/ /g, '').trim();
    });
  }
}
