<div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="64px">
  <span class="login-page-welcome fg-engie-gradient">DeCliC</span>
  <div
    *ngIf="loggedIn$ | async; else loggedOut"
    fxLayout="row"
    fxLayoutAlign="center start"
    fxLayoutGap="16px"
  >
    <button
      data-test="go-to-home"
      class="nj-btn nj-btn--outline-primary"
      (click)="onHome()"
    >
      {{ 'go to home' | titlecase }}
    </button>
    <button
      data-test="logout"
      class="nj-btn nj-btn--danger"
      (click)="onLogout()"
    >
      {{ 'logout' | titlecase }}
    </button>
  </div>
  <div
    fxLayout="column"
    fxLayoutAlign="center center"
    class="login-instructions"
  >
    <h5>
      First time user? You can go through the tutorial
      <a
        href="https://hcm55.sapsf.eu/sf/learning?destUrl=https%3a%2f%2fengieinfor2%2elms%2esapsf%2eeu%2flearning%2fuser%2fdeeplink%5fredirect%2ejsp%3flinkId%3dITEM%5fDETAILS%26componentID%3d46068%26componentTypeID%3dONLINE%26revisionDate%3d1717159090000%26targetStudentSysGUID%3d%26actingAs%3d%26fromSF%3dY&company=engieinforP3"
        target="_blank"
        >ES-Introduction to DeCliC</a
      >
      or follow the steps below:
    </h5>
    <ol>
      <li>
        <p>
          Go to
          <a href="https://engie.okta-emea.com/" target="_blank"
            ><strong class="blue-corporate">ENGIE Okta Portal</strong></a
          >
        </p>
      </li>
      <li>
        <p>Click on the <b>+Add Apps</b> button on left panel.</p>
      </li>
      <li>
        <p>
          Search for
          <strong class="fg-engie-gradient">DeCliC</strong>
          in the catalog of apps and click on <b>Add</b>.
        </p>
      </li>
      <li>
        <p>
          <b>
            Please note that it is not possible to access DeCliC directly via
            Okta.
          </b>
          After adding <strong class="fg-engie-gradient">DeCliC</strong> in
          Okta, you must use
          <span class="declic-engie">
            <a href="https://declic.engie.com/">
              <b>declic.engie.com</b>
            </a>
          </span>
          to access the tool.
        </p>
      </li>
    </ol>
  </div>
  <ng-template #loggedOut>
    <button
      type="button"
      class="nj-btn nj-btn--primary"
      (click)="onLogin()"
      [class.spinner]="loggingIn"
      [disabled]="loggingIn"
      dataCy="declic-login-button"
    >
      LOGIN
    </button>
  </ng-template>
</div>
