import {
  BasicEquipment,
  HasCapacity,
  HasColdSources,
  HasCOP,
  HasFirstYearValue,
  HasInputs,
  HasRefrigerants,
  HasYearlyProduction,
} from './equipment.types';
import { HasActivityId } from './generic.types';

export type Chiller = BasicEquipment &
  HasActivityId &
  HasFirstYearValue &
  HasYearlyProduction &
  HasCapacity &
  HasInputs &
  HasRefrigerants &
  HasColdSources &
  HasCOP;

export type ChillerForm = Pick<
  Chiller,
  | 'name'
  | 'cop'
  | 'capacity'
  | 'yearlyProduction'
  | 'inputTypes'
  | 'refrigerants'
  | 'coldSources'
>;

export enum ChillerType {
  ELECTRIC = 'electric',
  ABSORPTION = 'absorption',
  ELEC_ABSORPTION = 'electricAbsorption',
}

export const CHILLER_TYPES = Object.values<string>(ChillerType);

export const ELECTRIC_CHILLER_TYPES: string[] = [
  ChillerType.ELECTRIC,
  ChillerType.ELEC_ABSORPTION,
];
export const ABSORPTION_CHILLER_TYPES: string[] = [
  ChillerType.ABSORPTION,
  ChillerType.ELEC_ABSORPTION,
];
