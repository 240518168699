import { DeclicUtils } from 'declic-app/common';
import { EnvConfig } from 'declic-app/models/authentication/env-config.model';
import { Observable, of, throwError } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { DECLICONFIG } from '@declic/env';

export interface ConcurrentResponse {
  readonly count: number;
}

@Injectable({ providedIn: 'root' })
export class CerberusClient {
  constructor(
    @Inject(DECLICONFIG) private readonly config: EnvConfig,
    private readonly http: HttpClient,
  ) {}

  post<T>(projectId: string, url: string, body: unknown): Observable<T> {
    return this.checkConcurrency(projectId).pipe(
      mergeMap(() => this.http.post<T>(url, body)),
    );
  }

  put<T>(url: string, body: unknown): Observable<T> {
    return this.http.put<T>(url, body);
  }

  delete<T>(projectId: string, url: string): Observable<T> {
    return this.checkConcurrency(projectId).pipe(
      mergeMap(() => this.http.delete<T>(url)),
    );
  }

  private throwConcurrencyError(): Observable<{}> {
    return throwError({
      status: 409,
      error: {
        error:
          'Multiple users have this project open : saving your changes is currently not possible.',
      },
    });
  }

  private canDoRequest(response: ConcurrentResponse): boolean {
    return response.count <= 1;
  }

  private checkConcurrency(id: string): Observable<ConcurrentResponse> {
    return this.http
      .get<ConcurrentResponse>(this.getEndpointForCerberus(id))
      .pipe(
        mergeMap((rsp) =>
          this.canDoRequest(rsp) ? of(rsp) : this.throwConcurrencyError(),
        ),
      ) as Observable<ConcurrentResponse>;
  }

  private getEndpointForCerberus(id: string): string {
    return DeclicUtils.buildEndpoint(
      this.getBaseUrl(),
      this.getAccessingProjectEndpoint(),
      id,
    );
  }

  private getAccessingProjectEndpoint(): string {
    return this.config.api.endpoints['project']['count'];
  }

  private getBaseUrl(): string {
    return this.config.api.baseUrl;
  }
}
