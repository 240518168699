import { EnvConfig } from 'declic-app/models/authentication/env-config.model';

import { InjectionToken } from '@angular/core';
import { OktaAuthOptions } from '@okta/okta-auth-js';

export enum EnvStage {
  LOCAL = 'local',
  DEV = 'dev',
  QA = 'qa',
  DEMO = 'demo',
  STAGE = 'stage',
  PROD = 'prod',
}

export const DEV_STAGES: EnvStage[] = [
  EnvStage.DEV,
  EnvStage.QA,
  EnvStage.DEMO,
  EnvStage.STAGE,
];

export const ALERTING_STAGES: EnvStage[] = [EnvStage.DEV, EnvStage.QA];

export const endpoints = {
  project: {
    list: 'projects',
    single: 'projects/:id',
    results: 'projects/:id/results',
    compute: 'projects/:id/compute',
    export: 'projects/export/:id',
    share: 'projects/:id/share',
    count: 'projects/:id/countActiveUsers',
    accessStatus: 'projects/:id/accessStatus',
    projectPermission: 'projects/:id/permission',
  },
  user: {
    single: 'users/filter_email/:email',
  },
};

// eslint-disable-next-line
export function getNewEndpoints(): Record<string, string> {
  return {
    pseudoError: 'pseudoError',
    projects: 'projects',
    project: 'projects/{id}',
    products: 'projects/{id}/products',
    activities: 'projects/{id}/activities',
    computeProject: 'projects/{id}/compute',
    duplicateProject: 'projects/{id}/duplicate',
    results: 'projects/{id}/results',
    generateReport: 'projects/{id}/generateReport',
    product: 'projects/{project}/products/{id}',
    exportProject: 'projects/export/{id}',
    shareProject: 'projects/{id}/share',
    countActiveUsers: 'projects/{id}/countActiveUsers',
    accessStatus: 'projects/{id}/accessStatus',
    projectPermission: 'projects/{id}/permission',
    userByEmail: 'users/filter_email/{email}',
    powerGrids: 'assumptions/powerGridMix',
    gasMixes: 'assumptions/gasMixes',
    fuels: 'assumptions/fuels',
    mobilities: 'assumptions/mobility',
    fluids: 'assumptions/refrigerantFluids',
    efficiencyRates: 'assumptions/efficiencyRates',
    otherGases: 'assumptions/other_gases',
    countries: 'assumptions/countries',
    feedstock: 'assumptions/feedstock',
    electricity: 'assumptions/electricity',
    featureFlags: 'assets/feature-flags/{env}.json',
    equipments: 'projects/{projectId}/activities/{activityId}/equipments',
    equipment: 'projects/{projectId}/activities/{activityId}/equipments/{id}',
    historicalEquips: 'projects/{projectId}/activities/{activityId}/historical',
    activityEntities:
      'projects/{projectId}/activities/{activityId}/{entityType}',
    activityEntity:
      'projects/{projectId}/activities/{activityId}/{entityType}/{id}',
    histoEquip: 'projects/{projectId}/activities/{activityId}/historical/{id}',
    activity: 'projects/{projectId}/activities/{activityId}',
    v2Fuels: 'v2/assumptions/fuels',
    activitySummary: 'projects/{projectId}/activities/{activityId}/summary',
    assumptionProjFuels: 'v2/assumptions/fuels/project/{equipmentType}',
    assumptionProjElecs: 'v2/assumptions/electricity/project/{equipmentType}',
    assumptionRefrigerants:
      'v2/assumptions/refrigerants/project/{equipmentType}',
    gbuNSources: 'networks/sources/{projectType}',
    gbuEsSources: 'energysolutions/sources/{equipmentType}',
    gbuEsHistoricalTypes: 'energysolutions/historical/{activity}',
    legacyProjects: 'v1/projects',
    baseline: 'projects/{projectId}/activities/{activityId}/baseline',
    countriesWithRegion: 'countries',
    // taxoMatch
    taxoMatchResult:
      'projects/{projectId}/activities/{activityId}/taxomatchResult',
    taxoMatchMetadata: 'assets/taxomatch/metadata.json',
    questions:
      'projects/{projectId}/activities/{activityId}/taxomatch/questions/{alignment}',
    eligibilities: 'projects/{projectId}/activities/{activityId}/eligibilities',
    eligibility:
      'projects/{projectId}/activities/{activityId}/eligibilities/{eligibilityId}',
  };
}

export const version = {};

export const DECLICONFIG = new InjectionToken<DeclicConfig>('decliconfig');
export const BASE_URL = new InjectionToken<string>('baseurl');
export const ENDPOINTS = new InjectionToken<Record<string, string>>(
  'endpoints',
);

export const OKTA_CALLBACK_PATH = 'login/callback';

export const DeclicOkta = {
  issuer: 'https://et-tractebel.oktapreview.com/oauth2/default',
  redirectUri: window.location.origin + '/' + OKTA_CALLBACK_PATH,
};

export const DEFAULT_ALLOWED_ORIGINS = ['http://localhost'];

export interface DeclicConfig extends EnvConfig {
  readonly amplitudeAPIKey: string;
  readonly oktaAuth?: Pick<OktaAuthOptions, 'clientId'>;
  readonly appVersion?: string;
}
