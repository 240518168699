import { HttpClient } from '@angular/common/http';
import { inject, Injectable, Provider } from '@angular/core';
import { environment } from '@declic/env';
import {
  provideTransloco,
  Translation,
  TRANSLOCO_CONFIG,
  TRANSLOCO_LOADER,
  TRANSLOCO_MISSING_HANDLER,
  translocoConfig,
  TranslocoLoader,
  TranslocoMissingHandler,
} from '@jsverse/transloco';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  private http = inject(HttpClient);

  getTranslation(lang: string) {
    return this.http.get<Translation>(`/assets/i18n/${lang}.json`, {
      params: { v: environment.appVersion },
    });
  }
}

export const TRANSLOCO_PROVIDER = [
  {
    provide: TRANSLOCO_CONFIG,
    useValue: translocoConfig({
      availableLangs: ['en'],
      defaultLang: 'en',
      fallbackLang: 'en',
      prodMode: environment.production,
    }),
  },
  { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader },
];

export const NEW_TRANSLOCO_PROVIDER = provideTransloco({
  config: {
    availableLangs: ['en'],
    defaultLang: 'en',
    prodMode: environment.production,
  },
  loader: TranslocoHttpLoader,
});

export class FallbackToFalsy implements TranslocoMissingHandler {
  handle(): string {
    return '';
  }
}

export const provideTranslocoMissingHandler = () =>
  ({
    provide: TRANSLOCO_MISSING_HANDLER,
    useClass: FallbackToFalsy,
  }) as Provider;
