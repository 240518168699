import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeComma',
  standalone: false,
})
export class RemoveCommaPipe implements PipeTransform {
  transform(value: string): string {
    if (value !== undefined && value !== null) {
      return value.replace(/,/g, ' ');
    }
    return '';
  }
}
