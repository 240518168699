import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { RemoveCommaPipe } from './remove-comma.pipe';

@NgModule({
  declarations: [RemoveCommaPipe],
  imports: [CommonModule],
  exports: [RemoveCommaPipe],
})
export class RemoveCommafyModule {}
