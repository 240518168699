import { YearlyEmission } from 'declic-app/models';
import { ENV_CONFIG } from 'declic-app/services/auth-config/auth-config.service';
import { Observable } from 'rxjs';
import { debounceTime, delay, filter, take } from 'rxjs/operators';

import { ComponentType } from '@angular/cdk/portal';
import { forwardRef, Provider } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BASE_URL, ENDPOINTS, environment, getNewEndpoints } from '@declic/env';

import { generateEndpoint } from './api.util';
import { Coerce } from './coercion.utils';

export function take1Subscription<T>(
  stream: Observable<T>,
  callbackFn: (data: T) => void,
): void {
  stream.pipe(take(1)).subscribe(callbackFn);
}

export function filterOutUndefined<T>(stream: Observable<T>) {
  return stream.pipe(filter((data) => !!data));
}

export function reduceToDict<T>(
  list: T[],
  keyProperty: string,
): { [key: string]: T } {
  return Coerce.toArr(list).reduce((acc, curr) => {
    const key = curr[keyProperty];
    acc[key] = curr;
    return acc;
  }, {});
}

export function createCVAProvider(component: ComponentType<unknown>): Provider {
  return {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => component),
    multi: true,
  };
}

export function createValidatorProvider(
  component: ComponentType<unknown>,
): Provider {
  return {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => component),
    multi: true,
  };
}

export function debounceAwhile<T>(stream: Observable<T>): Observable<T> {
  return stream.pipe(debounceTime(100));
}

export function serializeObject(object: unknown): string {
  return JSON.stringify(object);
}

export function times100(num: number): number {
  return num * 100;
}

export function divide100(num: number): number {
  return num / 100;
}

export function resolveToZero(allegedNumber: unknown): number {
  return Number(allegedNumber) || 0;
}

export function arrayFromRange(from: number, to: number): Array<number> {
  return Array.from({ length: to - from + 1 }, (_, k) => k + from);
}

export function resolveToEmptyArray<T>(allegedList: T[]): T[] {
  return allegedList || [];
}

export function resolveToEmptyObj<T>(alleged: T): T {
  return (alleged || {}) as T;
}

export function dummyYearlyEmissions(
  years: number[],
  emissions: number = null,
): YearlyEmission[] {
  return years.map((year) => ({ year, emissions }));
}

function fixTo3Places(num: number): number {
  return num ? Number(num.toFixed(3)) : num;
}

function fixToNPlaces(num: number, places: number): number {
  return num ? Number(num.toFixed(places)) : num;
}

function removeDecimal(num: number): number {
  return Number(resolveToZero(num).toFixed());
}

function delayAwhile<T>($: Observable<T>): Observable<T> {
  return $.pipe(delay(100));
}

function getRandomInt(max: number) {
  return Math.floor(Math.random() * Math.floor(max));
}

function provideEnvironmentConfig(): Provider {
  return { provide: ENV_CONFIG, useValue: environment };
}

function provideBaseUrlAndEndpoints(): Provider[] {
  return [
    { provide: BASE_URL, useValue: environment.api.baseUrl },
    { provide: ENDPOINTS, useValue: getNewEndpoints() },
  ];
}

function buildEndpoint(
  baseUrl: string,
  endpoint: string,
  ...params: string[]
): string {
  return generateEndpoint(baseUrl, endpoint, ...params);
}

function isCurrentYear<T>(year: T): boolean {
  const currentYear = new Date().getFullYear();
  return Number(year) === currentYear;
}

function splitByDelimiter(
  value: string,
  delimiter: string = '-',
): [string, string | null, string | null, string | null] {
  if (!value) return ['', null, null, null];

  const parts = value.split(delimiter).map((part) => part.trim());
  return parts.length > 1
    ? [parts[0], parts[1], parts[2], parts[3]]
    : [parts[0], null, null, null];
}

function formatSource(efSource: string | null): string {
  if (!efSource) return '';
  return ` (${efSource})`;
}

function formatTechnology(efTechnology: string | null): string {
  if (!efTechnology) return '';
  return ` (${efTechnology})`;
}

function formatSubType(efName: string, efSubType: string | null): string {
  return efSubType ? `${efName} - ${efSubType}` : efName;
}

function formatDisplayName(name: string): string {
  const [efName, efSubType, efTechnology, efSource] =
    DeclicUtils.splitByDelimiter(name, ',');

  let baseName = formatSubType(efName, efSubType);
  baseName += formatTechnology(efTechnology);
  return (baseName += formatSource(efSource));
}

function normalizeString(value: string): string {
  return Coerce.toString(value).replace(/ /g, '').toLowerCase();
}

export const DeclicUtils = {
  filterOutUndefined,
  reduceToDict,
  resolveToEmptyArray,
  resolveToEmptyObj,
  take1Subscription,
  arrayFromRange,
  divide100,
  times100,
  fixTo3Places,
  fixToNPlaces,
  removeDecimal,
  resolveToZero,
  delayAwhile,
  debounceAwhile,
  dummyYearlyEmissions,
  getRandomInt,
  provideEnvironmentConfig,
  buildEndpoint,
  provideBaseUrlAndEndpoints,
  isCurrentYear,
  splitByDelimiter,
  formatDisplayName,
  normalizeString,
};
