import { AbstractControl, Validators } from '@angular/forms';
import { CustomUnit } from 'declic-app/common/label-maker';

export type AuxiliaryPropertiesManifest = { [prop: string]: AuxiliaryProperty };

export enum PropertyConstraint {
  PERCENTAGE,
  ZERO_TO_1,
  HOURS_IN_YEAR,
  INTEGER_ONLY,
}

export const ConstraintValidatorFns = {
  [PropertyConstraint.HOURS_IN_YEAR]: Validators.max(8760),
  [PropertyConstraint.INTEGER_ONLY]: (control: AbstractControl) => {
    const patternError = Validators.pattern('^[0-9]*$')(control);
    return patternError ? { integerOnly: true } : undefined;
  },
};

export interface AuxiliaryProperty {
  constraints: PropertyConstraint[];
  unit: string;
  customUnit?: CustomUnit;
  name?: string;
  hint?: string;
  extraLabel?: string;
  displayOnly?: boolean;
  averageOnly?: boolean;
  disabled?: boolean;
  labelTranslationKey?: string;
  helpMessageKey?: string;
}
