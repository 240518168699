<div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="16px">
  <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="16px">
    <div
      *ngFor="let value of values$ | async"
      fxLayout="column"
      fxLayoutAlign="start start"
    >
      <span class="yearly-values-value">{{
        value?.emissions | formatValue
      }}</span>
      <span class="font-smaller yearly-values-year">{{ value?.year }}</span>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="end end">
    <span class="yearly-values-unit">**in {{ unit }}</span>
  </div>
</div>
