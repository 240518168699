import { Entity } from 'declic-app/models/entity.model';
import { State } from 'declic-app/models/state.model';
import { Observable } from 'rxjs';

import { Directive } from '@angular/core';
import { filterNil, select, Store, StoreDef } from '@ngneat/elf';
import {
  addEntities,
  deleteEntities,
  EntitiesRef,
  EntitiesState,
  getActiveEntity,
  getActiveId,
  getAllEntities,
  getEntity,
  selectActiveEntity,
  selectActiveId,
  selectAllEntities,
  selectEntity,
  setActiveId,
  setEntities,
  updateEntities,
  upsertEntitiesById,
} from '@ngneat/elf-entities';

@Directive({
  standalone: false,
})
export abstract class EntityStateStore<
  T extends State &
    EntitiesState<EntitiesRef<'entities', 'ids', 'idKey'>> & {
      activeId: U['id'];
    },
  U extends Entity,
> {
  constructor(public store: Store<StoreDef<T>>) {}

  state$ = this.store;
  entities$ = this.store.pipe(selectAllEntities());
  selectedEntity$ = this.store.pipe(selectActiveEntity());
  loading$ = this.store.pipe(select((state) => state.loading));
  loaded$ = this.store.pipe(select((state) => state.loaded));
  error$ = this.store.pipe(select((state) => state.error));

  selectEntity(id: string): Observable<U> {
    return this.store.pipe(selectEntity(id));
  }

  selectAll(): Observable<U[]> {
    return this.store.pipe(selectAllEntities());
  }

  selectActive(): Observable<U> {
    return this.store.pipe(selectActiveEntity(), filterNil());
  }

  selectActiveId(): Observable<string> {
    return this.store.pipe(selectActiveId());
  }

  select(key: string) {
    return this.store.pipe(select((state) => state[key]));
  }

  getAllEntities(): U[] {
    return this.store.query(getAllEntities());
  }

  getEntity(id: string): U {
    return this.store.query(getEntity(id));
  }

  getActiveId(): string {
    return this.store.query(getActiveId);
  }

  getActive(): U {
    return this.store.query(getActiveEntity());
  }

  setState(newState: Partial<T>) {
    this.store.update((state) => ({ ...state, ...newState }));
  }

  setLoading(loading: boolean) {
    this.store.update((state) => ({ ...state, loading }));
  }

  clearState() {
    this.store.update(() => this.store.initialState);
  }

  setEntities(entities: Array<U>) {
    this.store.update(setEntities(entities));
  }

  addEntity(entity: U) {
    this.store.update(addEntities(entity));
  }

  upsertEntity(id: U['id'], entity: Partial<U>) {
    this.store.update(
      upsertEntitiesById(id, {
        updater: (prevValue) => ({ ...prevValue, ...entity }),
        creator: () => ({ id, ...entity }),
      }),
    );
  }
  updateEntity(id: U['id'], entity: Partial<U>) {
    this.store.update(
      updateEntities(id, (prevValue) => ({ ...prevValue, ...entity })),
    );
  }

  setActiveId(id: U['id'] | null) {
    this.store.update(setActiveId(id));
  }

  resetEntities() {
    this.store.reset();
  }

  remove(id: string): void {
    if (id === this.store.query(getActiveId)) {
      this.store.update(deleteEntities(id), setActiveId(null));
    } else {
      this.store.update(deleteEntities(id));
    }
  }

  setActive(id: string): void {
    this.store.update(setActiveId(id));
  }
}
