import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { CustomText } from './label-maker.types';

@Component({
  selector: 'declic-label-maker',
  imports: [],
  templateUrl: './label-maker.component.html',
  styleUrl: './label-maker.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelMakerComponent {
  parts = input<CustomText[]>();
}
