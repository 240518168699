import { State } from 'declic-app/models/state.model';
import { NetworksGeneralInfo } from 'declic-app/networks-project-emissions/components/general-info/generat-info.model';
import { AccessRight } from 'declic-app/services/cerberus';
import { projectLocation } from 'declic-project/components/common';
import { ProductsType } from 'declic-project/models';
import { ProjectStatus } from 'declic-project/services';

import { HasActivities } from '@declic/types';

import { JSONifiedProject } from './be-project.model';

export type BaseProject = Readonly<{
  id: string;
  globalBusinessUnit: GlobalBusinessUnit;
  location: projectLocation;
  countryCode: string;
  region?: string;
  period: [number, number];
  name: string;
  kpies?: string[];
  owner_id?: string;
  owner_name?: string;
  permissions?: AccessRight;
  date?: string;
  status?: ComputationStatus;
  calibrationStatus?: CalibrationStatus;
  shareInfo?: ProjectShareInfo[];
  reportStatus?: string;
  products?: ProductsType[];
  description?: string;
  installation?: boolean;
  salesRef?: string;
  networksGeneralInformation?: NetworksGeneralInfo;
}>;

export type ProjectWithActivities = BaseProject & HasActivities;

export type ProjectShareInfo = Readonly<{
  userId: string;
  permissions?: number;
  role?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
}>;

export enum ComputationStatus {
  DRAFT = 'compute_draft',
  COMPLETED = 'compute_completed',
  ERROR = 'compute_error',
  CALIBRATED = 'calibrated',
  CALIBRATING = 'calibrating',
}

export enum CalibrationStatus {
  IN_PROGRESS = 'in_progress',
  FAILED = 'failed',
  SUCCESS = 'success',
}

export enum GlobalBusinessUnit {
  RENEWABLES = 'Renewables',
  ENERGY_SOLUTIONS = 'Energy Solutions',
  THERMAL_AND_SUPPLY = 'Thermal & Supply',
  NETWORKS = 'Networks',
  GEMS = 'GEMS',
  FLEX_GEN = 'FlexGen',
}

export type UIState = Readonly<{
  deletingOne?: boolean;
  copying?: boolean;
  computing?: boolean;
  updating?: boolean;
}>;

export type ProjectStateModel = State &
  UIState &
  Readonly<{
    sharing?: boolean;
    lastCreated?: BaseProject;
    status?: ProjectStatus;
  }>;

export type LegacyProjectStateModel = State &
  Readonly<{
    lastCreated?: BaseProject;
    status?: ProjectStatus;
  }>;

export interface GetProjectsRsp {
  readonly projects: JSONifiedProject[];
}

export interface EditProjectRsp {
  readonly updatedProject: BaseProject;
}

export interface EditProjectResponse {
  readonly updatedProject: JSONifiedProject;
}
