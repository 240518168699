import { HasEquipmentEnergies } from './cogen.types';
import { EnergyType } from './energy.types';
import {
  BasicEquipment,
  HasInputs,
  WithMultipleEnergyDetails,
} from './equipment.types';

export type Exchanger = Omit<BasicEquipment, 'energyProducedType'> &
  HasEquipmentEnergies &
  HasInputs;

export type ExchangerForm = Pick<Exchanger, 'name' | 'energies' | 'inputTypes'>;

export const EXCHANGER_ENERGY_TYPES: EnergyType[] = [
  EnergyType.HEAT,
  EnergyType.COLD,
];

export type ExchangerTableRow = Exchanger &
  Omit<WithMultipleEnergyDetails, 'capacities'>;
